<template>
  <div class="pivot-component section-content">
    <div class="pivot-opts-bar">
      <div
        :class="{ 'opt-item': true, disabled: state.loading || state.error, enabled: !state.loading && !state.error }"
        @click="doExportCSV()"
      >
        <download-outlined />
      </div>
      <div
        :class="{ 'opt-item': true, disabled: state.loading || state.error, enabled: !state.loading && !state.error }"
        @click="openOptions(getFormatsModalId())"
      >
        <font-size-outlined />
      </div>
      <div
        :class="{ 'opt-item': true, disabled: state.loading || state.error, enabled: !state.loading && !state.error }"
        @click="openOptions(getOrderingModalId())"
      >
        <swap-outlined :rotate="90"></swap-outlined>
      </div>
      <div
        :class="{
          'opt-item': true,
          disabled: state.loading || state.error,
          enabled: !state.loading && !state.error,
          filtered: isFiltered(),
        }"
        @click="openOptions(getFiltersModalId())"
      >
        <filter-outlined />
      </div>
      <div
        :class="{ 'opt-item': true, disabled: state.loading || state.error, enabled: !state.loading && !state.error }"
        @click="openOptions(getEntitiesModalId())"
      >
        <deployment-unit-outlined />
      </div>
      <div
        :class="{ 'opt-item': true, disabled: state.loading || state.error, enabled: !state.loading && !state.error }"
        @click="openOptions(getSettingsModalId())"
      >
        <setting-outlined />
      </div>
    </div>
    <div class="loader-container" v-show="state.loading && !state.error"><Loader /></div>
    <div ref="pivotContainer" class="pivot-container" v-show="!state.loading && !state.emptyData && !state.error">
      <div class="pivot-draw-area">
        <div class="pivot-scroller">
          <div class="pivot-grid-box">
            <div class="pivot-grid-data"></div>
          </div>
        </div>
        <div class="pivot-header-column">
          <div class="pivot-header-column-dimension-name cell cell-header"></div>
          <div class="pivot-header-column-scroller-box">
            <div class="pivot-header-column-scroller">
              <div class="pivot-header-column-grid-box">
                <div class="pivot-header-column-grid"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="pivot-header-row">
          <div class="pivot-header-row-dimension-name"></div>
          <div class="pivot-header-row-scroller-box">
            <div class="pivot-header-row-scroller">
              <div class="pivot-header-row-grid-box">
                <div class="pivot-header-row-grid"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <canvas id="cellWidthCalculator" style="display: none"></canvas>
      <div class="pivot-tooltip"></div>
    </div>
    <div v-show="state.emptyData && !state.error && !state.loading" class="status-icon-container">
      <div class="warning-icon"><warning-outlined></warning-outlined></div>

      <span>Non ci sono dati da mostrare</span>
    </div>
    <div v-show="state.error && !state.emptyData" class="status-icon-container">
      <div class="error-icon"><exclamation-circle-outlined></exclamation-circle-outlined></div>

      <span>Errore durante il caricamento della pivot, consultare i log per dettagli</span>
    </div>
  </div>
  <pivot-settings v-if="state.modalVisible && state.modalId === getSettingsModalId()"> </pivot-settings>
  <pivot-entities v-if="state.modalVisible && state.modalId === getEntitiesModalId()"></pivot-entities>
  <pivot-ordering v-if="state.modalVisible && state.modalId === getOrderingModalId()"></pivot-ordering>
  <pivot-formats v-if="state.modalVisible && state.modalId === getFormatsModalId()"></pivot-formats>
  <pivot-filters v-if="state.modalVisible && state.modalId === getFiltersModalId()"></pivot-filters>
</template>

<script lang="ts">
import { PIVOT_PLOC_KEY } from "@/components/common/GUIConsts";
import { defineComponent, onMounted, ref, inject, onUnmounted } from "vue";
import {
  SettingOutlined,
  DeploymentUnitOutlined,
  FilterOutlined,
  DownloadOutlined,
  FontSizeOutlined,
  SwapOutlined,
  WarningOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";
import Loader from "../../controls/Loader.vue";
import { manageSubscription } from "@/components/common/SubscriptionManager";
import PivotSettings from "./PivotSettings.vue";
import PivotPLOC from "@/core/pivot/presentation/PivotPLOC";
import PivotEntities from "./PivotEntities.vue";
import PivotOrdering from "./PivotOrdering.vue";
import PivotFormats from "./PivotFormats.vue";
import PivotFilters from "./PivotFilters.vue";
import {
  ENTITIES_MODAL_ID,
  FILTERS_MODAL_ID,
  FORMATS_MODAL_ID,
  ORDERING_MODAL_ID,
  SETTINGS_MODAL_ID,
} from "@/core/pivot/common/PivotConsts";

export default defineComponent({
  components: {
    SettingOutlined,
    DeploymentUnitOutlined,
    FilterOutlined,
    DownloadOutlined,
    FontSizeOutlined,
    SwapOutlined,
    Loader,
    PivotSettings,
    PivotEntities,
    PivotOrdering,
    PivotFormats,
    PivotFilters,
    WarningOutlined,
    ExclamationCircleOutlined,
  },

  setup() {
    //Init component
    const pivotContainer = ref<HTMLElement>();
    const pivotPLOC = inject(PIVOT_PLOC_KEY) as PivotPLOC;
    let pivotState = manageSubscription(pivotPLOC);

    const doExportCSV = () => {
      pivotPLOC.doExportCSV();
    };

    const openOptions = (modalId: string) => {
      pivotPLOC.openOptionsModal(modalId);
    };

    onMounted(async () => {
      if (pivotContainer.value) {
        const result = await pivotPLOC.setupPivot(pivotContainer.value);
        if (!result.success) console.error("Pivot.vue/onMounted/setupPivot: cannot load pivot data " + result.errorMsg);
      } else console.error("Pivot.vue/onMounted: cannot mount pivot, pivotContainer not found.");
    });

    const getSettingsModalId = () => {
      return SETTINGS_MODAL_ID;
    };
    const getEntitiesModalId = () => {
      return ENTITIES_MODAL_ID;
    };
    const getOrderingModalId = () => {
      return ORDERING_MODAL_ID;
    };
    const getFormatsModalId = () => {
      return FORMATS_MODAL_ID;
    };
    const getFiltersModalId = () => {
      return FILTERS_MODAL_ID;
    };
    const isFiltered = () => {
      return pivotPLOC.isFiltered();
    };

    onUnmounted(() => {
      pivotPLOC.unMountPivot();
    });
    return {
      pivotContainer,
      state: pivotState,
      openOptions,
      doExportCSV,
      getSettingsModalId,
      getEntitiesModalId,
      getOrderingModalId,
      getFormatsModalId,
      getFiltersModalId,
      isFiltered,
    };
  },
});
</script>

<style lang="scss">
@import "../../../../../public/styles/variables.scss";

$opts-bar-height: 30px;

.pivot-component {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #f7f7f7;

  .loader-container {
    height: 100%;
  }

  .pivot-opts-bar {
    height: $opts-bar-height;
    background: white;
    border-bottom: 1px solid $gray-300;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 15px;

    @media only screen and (max-width: 950px) {
      padding-right: 10px;
    }

    .opt-item {
      height: 20px;
      width: 50px;
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $gray-600;
      border-left: 1px solid $gray-300;
      padding-left: 20px;
      margin-right: 20px;

      &:hover {
        color: $selected-color;
      }

      &.filtered {
        color: rgb(255, 208, 0);
      }

      @media only screen and (max-width: 600px) {
        padding-left: 5px;
        margin-right: 5px;
      }
    }
  }

  .pivot-component-title-box {
    flex: 0 0 auto;
    min-height: 26px;
    padding: 0 30px;
    overflow: hidden;
    box-sizing: border-box;
    .pivot-component-title {
      font-weight: 600;
      font-size: 15px;
      overflow-wrap: break-word;
      text-align: center;
    }

    .pivot-component-sub-title {
      font-weight: 300;
      font-size: 14px;
      overflow-wrap: break-word;
      text-align: center;
    }
  }

  .pivot-container {
    flex: 1 1 auto;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    --pivot-border-color: #dbdbdb;

    .pivot-draw-area {
      overflow: hidden;
      position: relative;
      width: 100%;
      height: 100%;

      &::before,
      &::after {
        content: "";
        position: absolute;
        z-index: 5;
        left: calc(var(--row-head-width) - 1px);
      }

      /* linea di separazione tra colonne di intestazione delle righe e i dati*/
      &::before {
        top: 0;
        width: 0;
        height: calc(var(--row-total-n) * var(--cell-height));
        padding-top: var(--row-head-height);
        border-left: 1px solid var(--pivot-border-color);
      }

      /* linea di separazione tra colonne di intestazione delle righe e i dati*/
      &::after {
        top: calc(var(--row-head-height) - 1px);
        width: var(--column-total-width);
        height: 0;
        border-bottom: 1px solid var(--pivot-border-color);
      }
    }

    .pivot-header-row {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      flex-direction: column;
      overflow-x: auto;
      overflow-y: hidden;
      max-height: 100%;
      width: var(--row-head-width);
      background-color: #f7f7f7;

      .pivot-header-row-dimension-name {
        position: relative;
        width: var(--rows-head-full-width);
        z-index: 3;
        height: var(--row-head-height);
        display: grid;
        grid-template-columns: var(--template-rows);
        background-color: #f7f7f7;
        flex: 0 0 auto;
        align-items: end;
        box-shadow: /*inset 1px 1px 0 var(--pivot-border-color),*/ inset -1px 0px 0 var(--pivot-border-color);

        .cell {
          box-shadow: 0px -1px 0 var(--pivot-border-color);
          background: none;
          &:first-child {
            border-left-color: var(--pivot-border-color);
          }
        }
      }

      .pivot-header-row-scroller-box {
        flex: 0 1 auto;
        width: var(--rows-head-full-width);
        height: calc(var(--row-total-n) * var(--cell-height));
        max-height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        .pivot-header-row-scroller {
          overflow: auto;
          position: relative;
          height: 100%;
          width: calc(var(--rows-head-full-width) + 50px);

          .pivot-header-row-grid-box {
            position: sticky;
            top: 0;
            left: 0;
            overflow: visible;
            width: 0;
            height: 0;

            .pivot-header-row-grid {
              grid-template-columns: var(--template-rows);
              grid-template-rows: repeat(var(--row-n), var(--cell-height));
            }
          }

          &::after {
            content: "";
            display: block;
            height: calc(var(--row-total-n) * var(--cell-height));
          }
        }
      }
    }

    .pivot-header-column {
      position: absolute;
      left: var(--row-head-width);
      top: 0;
      overflow-x: hidden;
      overflow-y: auto;
      width: var(--column-total-width);
      max-width: calc(100% - var(--row-head-width));
      height: var(--row-head-height);
      background-color: #f7f7f7;
      box-shadow: inset -1px 0 var(--pivot-border-color);

      &::before {
        content: "";
        position: absolute;
        top: 0;
        border-bottom: 1px solid var(--pivot-border-color);
        z-index: 3;
        width: 100%;
        height: 0;
        border: none;
      }

      .pivot-header-column-dimension-name {
        position: relative;
        z-index: 3;
        border-top-color: var(--pivot-border-color);
        border-width: calc(1px * var(--showHeaderColumnDimensionName));
        height: calc(var(--cell-height) * var(--showHeaderColumnDimensionName));
        border-top: none;
      }

      .pivot-header-column-scroller-box {
        --row-head-height-scroller: calc(var(--row-head-n) * var(--cell-height));
        width: var(--column-total-width);
        max-width: 100%;
        height: var(--row-head-height-scroller);
        overflow: hidden;

        .pivot-header-column-scroller {
          overflow-x: auto;
          overflow-y: hidden;
          position: relative;
          width: 100%;
          height: calc(var(--row-head-height-scroller) + 50px);

          .pivot-header-column-grid-box {
            position: sticky;
            left: 0;
            top: 0;
            overflow: visible;
            width: 0;
            height: 0;

            .pivot-header-column-grid {
              grid-template-columns: var(--template-columns);
              grid-template-rows: repeat(var(--row-head-n), var(--cell-height));
            }
          }

          &::after {
            content: "";
            display: block;
            width: var(--column-total-width);
            height: var(--row-head-height-scroller);
          }
        }
      }
    }

    .pivot-header-row-grid,
    .pivot-header-column-grid,
    .pivot-grid-data {
      display: grid;
      grid-gap: 0;
      will-change: transform;
      transform: translate(0px, 0px);
      background: white;
    }

    .pivot-scroller {
      display: inline-block;
      overflow: auto;
      overscroll-behavior: none;
      position: relative;
      margin-top: var(--row-head-height);
      margin-left: var(--row-head-width);
      max-width: calc(100% - var(--row-head-width));
      max-height: calc(100% - var(--row-head-height));

      .pivot-grid-box {
        position: sticky;
        top: 0;
        left: 0;
        overflow: visible;
        width: 0;
        height: 0;
      }

      &::after {
        display: block;
        content: "";
        height: calc(var(--row-total-n) * var(--cell-height));
        width: var(--column-total-width);
        z-index: -1;
      }
    }

    .pivot-grid-data {
      position: absolute;
      overflow: hidden;
      top: 0;
      left: 0;
      grid-template-columns: var(--template-columns);
      grid-template-rows: repeat(var(--row-n), var(--cell-height));
      box-shadow: 0 1px 0 #dbdbdb;
    }

    .cell {
      text-rendering: optimizeLegibility;
      border: 1px solid transparent;
      border-bottom-color: var(--pivot-border-color);
      border-right-color: var(--pivot-border-color);
      height: var(--cell-height);
      line-height: var(--cell-height);
      padding: 0 8px;
      box-sizing: border-box;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px;
      text-align: right;

      @media only screen and (max-width: 600px) {
        font-size: 12px;
      }

      &.close::before,
      &.open::before {
        content: "▸";
        padding-right: 5px;
        cursor: pointer;
      }
      &.open::before {
        content: "▾";
      }

      &.cell-header {
        font-weight: 600;
        text-align: left;
      }

      &.cell-header-row:not(.measure) {
        border-bottom-color: transparent;
      }

      &.cell-header-row.first-of-row {
        border-left-color: var(--pivot-border-color);
      }

      &.cell-header-row.last-of-list {
        border-bottom-color: var(--pivot-border-color);
      }

      &.cell-header-row.index-new {
        box-shadow: 0 -1px 0 var(--pivot-border-color);
      }

      &.cell-header-row.index-span {
        border-right-color: transparent;
      }

      &.cell-header-column:not(.measure) {
        border-right-color: transparent;
      }

      &.cell-header-column.index-new {
        box-shadow: -1px 0 0 #dbdbdb;
      }

      &.cell-header-column.index-span {
        border-top-color: transparent;
      }

      &.cell-header-column.last-of-row {
        border-right-color: var(--pivot-border-color);
      }

      &.data-subtotal {
        background-color: #effeff;
      }
      &.data-total {
        background-color: #e3efff;
      }
    }

    .pivot-tooltip {
      position: fixed;
      visibility: hidden;
      background-color: #333;
      color: #fff;
      padding: 5px;
      border-radius: 5px;
      z-index: 999;
      opacity: 0;
      transition: opacity 0.3s;
    }
  }
}
</style>
