import { PLOC } from "@/core/common/presentation/PLOC";
import DoLoginUseCase from "../domain/DoLoginUseCase";
import { Login } from "../domain/Login";
import { loginInitialState, LoginState } from "./LoginState";
import { DoLogoutUseCase } from "../domain/DoLogoutUseCase";

export class LoginPLOC extends PLOC<LoginState> {
  private login: Login;
  constructor(login: Login) {
    super(loginInitialState);
    this.login = login;
  }

  public async doLogin() {
    const loginResponse = await DoLoginUseCase.doLogin(
      this.login,
      this.state.mail,
      this.state.password,
      this.state.captchaOk
    );
    this.changeState({
      ...this.state,
      loginOk: loginResponse.success,
      loginErrorMsg: loginResponse.errorMsg,
      loggedUserId: loginResponse.userId,
      loginTried: true,
    });
  }

  public tryLoadLoggedUser() {
    const loggedUserId = DoLoginUseCase.tryLoadLoggedUser();
    if (loggedUserId)
      this.changeState({
        ...this.state,
        loggedUserId: loggedUserId,
        loginOk: true,
      });
  }

  public async doRecaptcha(token: string) {
    const recaptchaResponse = await DoLoginUseCase.doRecaptcha(this.login, token);
    this.changeState({
      ...this.state,
      captchaOk: recaptchaResponse.success,
    });
  }

  public recaptchaExpired() {
    this.changeState({
      ...this.state,
      captchaOk: false,
    });
  }

  public resetLoginInfo() {
    this.changeState({
      ...this.state,
      mail: "",
      password: "",
      loginOk: false,
      loginErrorMsg: "",
      loggedUserId: "",
      loginTried: false,
      captchaOk: true,
    });
  }

  public async doLogout() {
    const logoutOk = await DoLogoutUseCase.doLogout(this.login);
    if (logoutOk) this.resetLoginInfo();
  }
}
