import {
  AVAILABLE_DIMENSIONS_LIST_ID,
  AVAILABLE_MEASURES_LIST_ID,
  COLUMNS_LIST_ID,
  DIM_LIST_TYPE,
  MEAS_LIST_TYPE,
  ROWS_LIST_ID,
  SELECTED_MEASURES_LIST_ID,
} from "../common/PivotConsts";
import { IEntity, IPivotOptions } from "../domain/PivotOptionsDS";

export default class PivotEntitiesMover {
  private draggedElementFromId: string;
  private opts: IPivotOptions;
  private availableDimensions: Array<IEntity>;
  private availableMeasures: Array<IEntity>;

  constructor(opts: IPivotOptions, availableDimensions: Array<IEntity>, availableMeasures: Array<IEntity>) {
    this.draggedElementFromId = "";
    this.opts = opts;
    this.availableDimensions = availableDimensions;
    this.availableMeasures = availableMeasures;
  }

  public startDragEntity = (evt: DragEvent, alias: string, fromId: string) => {
    if (evt && evt.dataTransfer) {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("alias", alias);
      evt.dataTransfer.setData("fromId", fromId);
      this.draggedElementFromId = fromId;
    }
  };

  public onDropEntity = (evt: DragEvent, targetListId: string) => {
    if (evt && evt.dataTransfer) {
      const alias = evt.dataTransfer.getData("alias");
      const fromId = evt.dataTransfer.getData("fromId");

      this.moveEntity(targetListId, fromId, alias);
    }
  };

  public moveEntity(targetListId: string, fromId: string, entityAlias: string) {
    if (this.dropAllowed(targetListId, fromId)) {
      const fromList = this.id2List(fromId);
      const targetList = this.id2List(targetListId);

      const idx = fromList.findIndex((dim: IEntity) => dim.alias === entityAlias);
      const entity = fromList[idx];
      fromList.splice(idx, 1);
      targetList.push(entity);
    }
  }

  private id2List = (id: string) => {
    let list = new Array<IEntity>();
    switch (id) {
      case ROWS_LIST_ID:
        list = this.opts.rows;
        break;
      case COLUMNS_LIST_ID:
        list = this.opts.columns;
        break;
      case SELECTED_MEASURES_LIST_ID:
        list = this.opts.measures;
        break;
      case AVAILABLE_DIMENSIONS_LIST_ID:
        list = this.availableDimensions;
        break;
      case AVAILABLE_MEASURES_LIST_ID:
        list = this.availableMeasures;
        break;
    }
    return list;
  };

  private getListType = (listId: string) => {
    const onlyDimsLists = [ROWS_LIST_ID, COLUMNS_LIST_ID, AVAILABLE_DIMENSIONS_LIST_ID];
    if (onlyDimsLists.indexOf(listId) >= 0) return DIM_LIST_TYPE;
    else return MEAS_LIST_TYPE;
  };

  private dropAllowed = (targetListId: string, fromListId: string) => {
    const targetListType = this.getListType(targetListId);
    const fromListType = this.getListType(fromListId);
    return targetListType === fromListType;
  };

  public onDragOver = (evt: DragEvent, targetListId: string) => {
    if (evt && evt.dataTransfer) {
      const isDropAllowed = this.dropAllowed(targetListId, this.draggedElementFromId);

      if (isDropAllowed) {
        evt.dataTransfer.dropEffect = "move";
      } else {
        evt.dataTransfer.dropEffect = "none";
      }
    }
  };

  private from2TargetList(fromListId: string) {
    let targetListId = "";
    switch (fromListId) {
      case COLUMNS_LIST_ID:
        targetListId = AVAILABLE_DIMENSIONS_LIST_ID;
        break;
      case ROWS_LIST_ID:
        targetListId = COLUMNS_LIST_ID;
        break;
      case AVAILABLE_DIMENSIONS_LIST_ID:
        targetListId = ROWS_LIST_ID;
        break;
      case AVAILABLE_MEASURES_LIST_ID:
        targetListId = SELECTED_MEASURES_LIST_ID;
        break;
      case SELECTED_MEASURES_LIST_ID:
        targetListId = AVAILABLE_MEASURES_LIST_ID;
        break;
    }
    return targetListId;
  }

  public onEntityDoubleClick = (evt: Event) => {
    if (evt && evt.target) {
      const entityElement = evt.target as HTMLElement;
      const entityAlias = entityElement.id;
      const fromListId = entityElement.getAttribute("list-id") || "";
      const targetListId = this.from2TargetList(fromListId);
      this.moveEntity(targetListId, fromListId, entityAlias);
    }
  };
}
