import { IEntity, IPivotOptions } from "../PivotOptionsDS";
import PivotEntitiesUtils from "./PivotEntitiesUtils";

export default class PivotOrdering {
  public static formatOrderBy(orderById: string, opts: IPivotOptions) {
    const measure = opts.measures.find((meas: IEntity) => {
      return meas.alias === orderById;
    });
    if (measure) return measure.desc;
    else {
      console.warn("PivotOrdering/formatOrderBy:measure " + orderById + " not found, using default");
      return "Per Valore";
    }
  }

  public static setEntityOrderBy(orderByValue: string, entityAlias: string, opts: IPivotOptions) {
    const dimension = PivotEntitiesUtils.getSelectedDimensions(opts).find((dim: IEntity) => {
      return dim.alias === entityAlias;
    });
    if (dimension) dimension.orderBy = orderByValue;
    else console.warn("PivotOrdering/setEntityOrderBy:entity " + entityAlias + " not found");
  }
}
