import { LoginResponse } from "./LoginResponse";
import { RecaptchaResponse } from "./RecaptchaResponse";

export abstract class Login {
  static LOGGED_USER_KEY = "loggedUserId";
  public abstract doLogin(mail: string, password: string): Promise<LoginResponse>;

  public abstract doRecaptcha(token: string): Promise<RecaptchaResponse>;
  public abstract doLogout(): Promise<boolean>;
}
