import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  class: "list-viewport",
  style: { height: '100%' }
}
const _hoisted_2 = { class: "alignment-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "scroller",
      onScroll: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateScrollingList && _ctx.updateScrollingList(...args))),
      ref: "scroller",
      style: _normalizeStyle({ '--full-height': _ctx.state.totalHeight + 'px' })
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: "list-items-container",
          style: _normalizeStyle({
            'will-change': 'transform',
            transform: 'translateY(' + _ctx.state.offsetY + 'px)',
          })
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.visibleNodeCount, (n) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass({
              'list-item': true,
              even: _ctx.isListItem(n) && _ctx.absoluteItemIndex(n) % 2 === 0,
              odd: _ctx.isListItem(n) && _ctx.absoluteItemIndex(n) % 2 !== 0,
            }),
              key: _ctx.absoluteItemIndex(n)
            }, [
              (_ctx.isListItem(n))
                ? _renderSlot(_ctx.$slots, "item", _normalizeProps(_mergeProps({ key: 0 }, { itemIndex: _ctx.absoluteItemIndex(n) })))
                : (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    style: _normalizeStyle({ height: _ctx.itemHeight + 'px' })
                  }, null, 4))
            ], 2))
          }), 128))
        ], 4)
      ])
    ], 36)
  ]))
}