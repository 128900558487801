import {
  ANALYSIS_PAGE_TITLE,
  CALENDAR_PAGE_TITLE,
  PERFORMANCES_PAGE_TITLE,
  USERS_PAGE_TITLE,
} from "@/core/common/utils/ResourceStrings";
import PageDS from "./PageDS";
import { ANALYSIS_PAGE_ID, CALENDAR_PAGE_ID, LOGIN_PAGE_ID, PERFORMANCES_PAGE_ID, USERS_PAGE_ID } from "./PagesConsts";

interface PagesMap {
  [id: string]: PageDS;
}

export const pagesMap: PagesMap = {
  [LOGIN_PAGE_ID]: {
    pageId: LOGIN_PAGE_ID,
    pageTitle: "",
  },
  [CALENDAR_PAGE_ID]: {
    pageId: CALENDAR_PAGE_ID,
    pageTitle: CALENDAR_PAGE_TITLE,
  },
  [ANALYSIS_PAGE_ID]: {
    pageId: ANALYSIS_PAGE_ID,
    pageTitle: ANALYSIS_PAGE_TITLE,
  },
  [USERS_PAGE_ID]: { pageId: USERS_PAGE_ID, pageTitle: USERS_PAGE_TITLE },
  [PERFORMANCES_PAGE_ID]: {
    pageId: PERFORMANCES_PAGE_ID,
    pageTitle: PERFORMANCES_PAGE_TITLE,
  },
};
