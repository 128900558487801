import { CELL_HEIGHT, COLUMN_WIDTH, VALUES_SEPARATOR } from "../common/PivotConsts";
import { getDescFromCode, valueIsValid } from "../common/PivotUtils";
import { IPivotChunkIndex } from "../domain/PivotDS";
import { IPivotOptions } from "../domain/PivotOptionsDS";
import CellWidthCalculator from "./CellWidthCalculator";
import ChunkManager from "./ChunkManager";

export default class PivotColumnsHead {
  private pivotColumnsHeadDOM: HTMLElement;
  private columnsHeadCells: Map<string, HTMLElement>;
  private nRows: number;
  private rowsChanged: boolean;
  private domHeight: number;
  private fullHeight: number;
  private cellWidthCalculator: CellWidthCalculator;

  constructor(pivotColumnsHeadDOM: HTMLElement, cellWidthCalculator: CellWidthCalculator) {
    this.pivotColumnsHeadDOM = pivotColumnsHeadDOM;
    this.columnsHeadCells = new Map();
    this.nRows = 0;
    this.rowsChanged = false;
    this.domHeight = 0;
    this.fullHeight = 0;
    this.cellWidthCalculator = cellWidthCalculator;
  }

  public getNRows(): number {
    return this.nRows;
  }

  public isRowsChanged(): boolean {
    return this.rowsChanged;
  }

  public getDomHeight(): number {
    return this.domHeight;
  }

  public getFullHeight(): number {
    return this.fullHeight;
  }

  public calculateHeight(
    maxOpenedFieldsOnColumn: number,
    pivotContainerCurrentHeight: number,
    pivotDataFullHeight: number,
    showColumnDimsDescrHead: boolean
  ) {
    const nRows = maxOpenedFieldsOnColumn;
    this.rowsChanged = nRows !== this.nRows;
    this.nRows = nRows;

    this.domHeight = (this.nRows + +showColumnDimsDescrHead) * CELL_HEIGHT;
    this.fullHeight = this.domHeight;
    const halfHeight = Math.floor(pivotContainerCurrentHeight / 2);
    if (this.domHeight + pivotDataFullHeight > pivotContainerCurrentHeight && this.domHeight > halfHeight) {
      this.domHeight = halfHeight;
      if (pivotContainerCurrentHeight < halfHeight) {
        this.domHeight += halfHeight - pivotContainerCurrentHeight;
      }
    }
  }

  public setCSSTranslateProp(translateColumn: number) {
    this.pivotColumnsHeadDOM.style.setProperty("transform", `translate(${translateColumn}px,0px)`);
  }

  public drawTemplate(nDataColumnsDOM: number) {
    this.columnsHeadCells.clear();
    for (let i = 0; i < this.nRows; i++) {
      for (let j = 0; j < nDataColumnsDOM; j++) {
        const columnHeadCell = document.createElement("div");
        columnHeadCell.setAttribute("data-cell-type", "columnHead");
        this.columnsHeadCells.set(`${i}-${j}`, columnHeadCell);
      }
    }
    this.pivotColumnsHeadDOM.replaceChildren(...Array.from(this.columnsHeadCells.values()));
  }

  private internalPopulateTotalInfo(
    columnUnique: Array<string>,
    pivotIndex: IPivotChunkIndex,
    keyContainMeasure: number
  ) {
    const isTotalColumn = columnUnique?.length === 0;
    let isSubTotalColumn =
      columnUnique?.length && columnUnique?.length < pivotIndex.maxOpenedFieldsOnColumn - keyContainMeasure;
    let fieldIndex = 0;
    let subTotalKey = "";
    while (isSubTotalColumn && fieldIndex < columnUnique.length) {
      subTotalKey += subTotalKey ? VALUES_SEPARATOR + columnUnique[fieldIndex] : columnUnique[fieldIndex];
      isSubTotalColumn = pivotIndex.openedColumns.has(subTotalKey);
      fieldIndex++;
    }

    return { isTotalColumn, isSubTotalColumn };
  }

  public calculateColumnTemplate(nDataColumnsDOM: number, firstColumnAbs: number) {
    let columnTemplate = "";
    let prevColumnDistance = firstColumnAbs * COLUMN_WIDTH;
    for (let i = 0; i < nDataColumnsDOM; i++) {
      const value = (i + 1 + firstColumnAbs) * COLUMN_WIDTH - prevColumnDistance;
      prevColumnDistance = (i + 1 + firstColumnAbs) * COLUMN_WIDTH;
      columnTemplate += ` ${value || 0}px`;
    }
    return columnTemplate;
  }

  public fillTemplateWithData(
    firstColumnAbs: number,
    pivotIndex: IPivotChunkIndex,
    opts: IPivotOptions,
    nDataColumnsDOM: number,
    chunkManager: ChunkManager
  ) {
    const keyContainMeasure = +opts.measuresOnColumn;
    let firtsColumnUnique =
      pivotIndex.columnsUniques?.[firstColumnAbs - 1]?.slice(0, -keyContainMeasure || undefined) || [];
    for (let j = 0; j < nDataColumnsDOM; j++) {
      const jColumnAbs = j + firstColumnAbs;
      const relativeColumn = jColumnAbs - chunkManager.getChunkStartColumn();
      const columnUnique = pivotIndex.columnsUniques?.[relativeColumn]?.slice(0, -keyContainMeasure || undefined);

      const totalInfo = this.internalPopulateTotalInfo(columnUnique, pivotIndex, keyContainMeasure);

      let isParentFirst = false;
      let isParentOpen = true;
      for (let i = 0; i < pivotIndex.maxOpenedFieldsOnColumn; i++) {
        const columnCellDOM = this.columnsHeadCells.get(`${i}-${j}`);
        if (columnCellDOM) {
          let cssClass = "";
          let textContent = "";
          let pathColumn = "";
          if (columnUnique) {
            cssClass += "cell cell-header cell-header-column";
            const isMeasure = keyContainMeasure && i === pivotIndex.maxOpenedFieldsOnColumn - keyContainMeasure;
            if (isMeasure) {
              cssClass += " measure";
              textContent = getDescFromCode(
                pivotIndex.columnsUniques?.[relativeColumn]?.slice(-1)?.[0],
                pivotIndex.codeToDesc
              );
            } else if (totalInfo.isSubTotalColumn || totalInfo.isTotalColumn) {
              if (
                (firtsColumnUnique.length !== columnUnique.length || pivotIndex.maxOpenedFieldsOnColumn === 1) &&
                i >= columnUnique.length
              ) {
                firtsColumnUnique = columnUnique;
                isParentFirst = true;
              }

              if (isParentFirst) {
                if (i === columnUnique.length) {
                  if (totalInfo.isTotalColumn) {
                    textContent = "Totale";
                  } else {
                    textContent = `${getDescFromCode(
                      columnUnique.slice(-1)?.[0],
                      pivotIndex.codeToDesc,
                      opts.columns[columnUnique.length - 1].alias
                    )} [${"Totale"}]`;
                  }
                }
              }
            } else if (i < columnUnique.length) {
              if (isParentFirst || (firtsColumnUnique[i] !== columnUnique[i] && valueIsValid(columnUnique[i]))) {
                isParentFirst = true;
                firtsColumnUnique.splice(i, pivotIndex.maxOpenedFieldsOnColumn, columnUnique[i]);
                if (i < opts.columns.length - 1) {
                  pathColumn = columnUnique.slice(0, i + 1).join(VALUES_SEPARATOR);
                  let isOpen = false;
                  if (isParentOpen) {
                    isOpen = pivotIndex.openedColumns.has(pathColumn);
                    isParentOpen = isOpen;
                    cssClass += isOpen ? " open" : " close";
                  }
                }
                textContent = getDescFromCode(columnUnique?.[i], pivotIndex.codeToDesc, opts.columns[i].alias);
              }
            }

            if (jColumnAbs === pivotIndex?.allColumnsLength - 1) {
              cssClass += " last-of-row";
            }
            if (!isMeasure) {
              if (
                i > columnUnique.length - +!(totalInfo.isSubTotalColumn || totalInfo.isTotalColumn) &&
                i < pivotIndex.maxOpenedFieldsOnColumn - keyContainMeasure
              ) {
                cssClass += " index-span";
              }
              if (isParentFirst) {
                cssClass += " index-new";
              }
            }
          }
          columnCellDOM.setAttribute("class", cssClass);
          columnCellDOM.setAttribute("data-head-path", pathColumn);
          columnCellDOM.textContent = textContent;
          const contentWidth = this.cellWidthCalculator.calculateCellWidth(textContent);
          if (contentWidth + CellWidthCalculator.TOLERANCE > COLUMN_WIDTH) {
            columnCellDOM.classList.add("need-tooltip");
          }
        }
      }
    }
  }
}
