import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Login = _resolveComponent("Login")!
  const _component_InternalApp = _resolveComponent("InternalApp")!
  const _component_modal_confirm = _resolveComponent("modal-confirm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createVNode(_component_Login, null, null, 512), [
      [_vShow, _ctx.appState.currentPage === _ctx.getLoginPageId()]
    ]),
    (_ctx.appState.currentPage !== _ctx.getLoginPageId() && _ctx.appState.loggedIn)
      ? (_openBlock(), _createBlock(_component_InternalApp, { key: 0 }))
      : _createCommentVNode("", true),
    _withDirectives(_createVNode(_component_modal_confirm, {
      title: 'Sessione scaduta',
      body: 'Sarà necessario rieffettuare il login',
      showBtnOk: true,
      btnClickFn: _ctx.onSessionExpiredOk
    }, null, 8, ["btnClickFn"]), [
      [_vShow, _ctx.appState.sessionExpired]
    ])
  ], 64))
}