import { CELL_HEIGHT, COLUMN_WIDTH, VALUES_SEPARATOR } from "../common/PivotConsts";
import { IPivotChunkIndex } from "../domain/PivotDS";
import { IPivotOptions } from "../domain/PivotOptionsDS";
import ChunkManager from "./ChunkManager";

export default class PivotDataGrid {
  private pivotDataGridDOM: HTMLElement;
  private dataCells: Map<string, HTMLElement>;
  private domWidth: number;
  private nColumnsDOM: number;
  private nRowsDOM: number;
  private nAllDataColumns: number;
  private nAllDataRows: number;
  private dataGridFullWidth: number;
  private dataGridFullHeight: number;
  private bigTotalStartRow: number;
  private bigTotalStartColumn: number;
  private rowsChanged: boolean;
  private columnsChanged: boolean;
  private cellKeyLength: number;

  constructor(pivotDataGridDOM: HTMLElement) {
    this.pivotDataGridDOM = pivotDataGridDOM;
    this.dataCells = new Map();
    this.domWidth = 0;
    this.nColumnsDOM = 0;
    this.nRowsDOM = 0;
    this.nAllDataColumns = 0;
    this.nAllDataRows = 0;
    this.dataGridFullWidth = 0;
    this.dataGridFullHeight = 0;
    this.bigTotalStartRow = Infinity;
    this.bigTotalStartColumn = Infinity;
    this.rowsChanged = false;
    this.columnsChanged = false;
    this.cellKeyLength = 0;
  }

  public getDomWidth(): number {
    return this.domWidth;
  }

  public getNColumnsDOM(): number {
    return this.nColumnsDOM;
  }

  public getNRowsDOM(): number {
    return this.nRowsDOM;
  }

  public getNAllDataColumns(): number {
    return this.nAllDataColumns;
  }

  public getNAllDataRows(): number {
    return this.nAllDataRows;
  }

  public getDataGridFullWidth(): number {
    return this.dataGridFullWidth;
  }

  public getDataGridFullHeight(): number {
    return this.dataGridFullHeight;
  }

  public getBigTotalStartRow(): number {
    return this.bigTotalStartRow;
  }

  public getBigTotalStartColumn(): number {
    return this.bigTotalStartColumn;
  }

  public isRowsChanged(): boolean {
    return this.rowsChanged;
  }

  public isColumnsChanged(): boolean {
    return this.columnsChanged;
  }

  public calculateFullSizing(pivotIndex: IPivotChunkIndex) {
    this.nAllDataColumns = pivotIndex?.allColumnsLength || 0;
    this.nAllDataRows = pivotIndex?.allRowsLength || 0;
    this.dataGridFullWidth = COLUMN_WIDTH * pivotIndex?.allColumnsLength || 0;
    this.dataGridFullHeight = this.nAllDataRows * CELL_HEIGHT;
  }

  public calculateDOMSizing(
    rowsHeadDomWidth: number,
    columnsHeadDomHeight: number,
    pivotContainerCurrentWidth: number,
    pivotContainerCurrentHeight: number
  ) {
    this.domWidth = Math.floor(pivotContainerCurrentWidth - rowsHeadDomWidth);

    const nColumnsDOM = Math.ceil(this.domWidth / COLUMN_WIDTH) + 1;
    this.columnsChanged = nColumnsDOM !== this.nColumnsDOM;
    this.nColumnsDOM = nColumnsDOM;
    if (this.nColumnsDOM > this.nAllDataColumns) {
      this.nColumnsDOM = this.nAllDataColumns;
    }

    const nRowsDOM = Math.ceil((pivotContainerCurrentHeight - columnsHeadDomHeight) / CELL_HEIGHT) + 1;
    this.rowsChanged = nRowsDOM !== this.nRowsDOM;
    this.nRowsDOM = nRowsDOM;
    if (this.nRowsDOM > this.nAllDataRows) {
      this.nRowsDOM = this.nAllDataRows;
    }
  }

  public calculateTotalsPositioning(opts: IPivotOptions) {
    this.bigTotalStartRow = this.nAllDataRows - (!opts.measuresOnColumn ? opts.measures.length : 1);
    if (opts.columns.length > 0) {
      this.bigTotalStartColumn =
        this.nAllDataColumns - (opts.measuresOnColumn && opts.columns.length > 0 ? opts.measures.length : 1);
    }
  }

  public setCSSTranslateProp(translateRow: number, translateColumn: number) {
    this.pivotDataGridDOM.style.setProperty("transform", `translate(${translateColumn}px,${translateRow}px)`);
  }

  public drawTemplate() {
    this.dataCells.clear();
    for (let i = 0; i < this.nRowsDOM; i++) {
      for (let j = 0; j < this.nColumnsDOM; j++) {
        this.dataCells.set(`${i}-${j}`, document.createElement("div"));
      }
    }
    this.pivotDataGridDOM.replaceChildren(...Array.from(this.dataCells.values()));
  }

  private buildCellKey(rowUnique: Array<string>, columnUnique: Array<string>, opts: IPivotOptions) {
    let idCell: Array<string> = [];
    if (opts.measuresOnColumn) {
      idCell = [...rowUnique, ...columnUnique];
    } else {
      const dimensionsPart = rowUnique.slice(0, rowUnique.length - 1);
      const measurePart = rowUnique[rowUnique.length - 1];
      idCell = [...dimensionsPart, ...columnUnique, measurePart];
    }

    return idCell;
  }

  public fillTemplateWithData(
    firstRowAbs: number,
    firstColumnAbs: number,
    pivotIndex: IPivotChunkIndex,
    opts: IPivotOptions,
    chunkManager: ChunkManager
  ) {
    this.cellKeyLength = opts.columns.length + opts.rows.length + 1;

    for (let i = 0; i < this.nRowsDOM; i++) {
      for (let j = 0; j < this.nColumnsDOM; j++) {
        const dataCellDOM = this.dataCells.get(`${i}-${j}`);

        if (dataCellDOM) {
          const iRowAbs = i + firstRowAbs;
          const iColumnAbs = j + firstColumnAbs;

          const rowUnique = pivotIndex.rowsUniques?.[iRowAbs - chunkManager.getChunkStartRow()];
          const columnUnique = pivotIndex.columnsUniques?.[iColumnAbs - chunkManager.getChunkStartColumn()];

          let cssClass = "cell";
          let textContent = "";
          if (rowUnique && columnUnique) {
            const idCellArr = this.buildCellKey(rowUnique, columnUnique, opts);
            const idCell = idCellArr.join(VALUES_SEPARATOR);

            if (
              (opts.bigTotalOnRow && iRowAbs >= this.bigTotalStartRow) ||
              (opts.bigTotalOnColumn && iColumnAbs >= this.bigTotalStartColumn)
            ) {
              cssClass += " data-total";
            } else if (idCellArr.length < this.cellKeyLength) {
              cssClass += " data-subtotal";
            }

            textContent = pivotIndex.valuesTreeMap.get(idCell)?.toString() || "";
          }

          dataCellDOM.setAttribute("class", cssClass);
          dataCellDOM.textContent = textContent;
        }
      }
    }
  }
}
