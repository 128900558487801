import { IOpening, IOptionsApplied, IPivotChunkIndex } from "../domain/PivotDS";
import IOnMessageResponseInternal from "../worker/IOnMessageResponseInternal";
import PivotContainer from "./PivotContainer";

export default class WorkerListener extends IOnMessageResponseInternal {
  private pivotContainer: PivotContainer;

  constructor(pivotContainer: PivotContainer) {
    super();
    this.pivotContainer = pivotContainer;
  }

  onApplyOptions(result: IOptionsApplied) {
    this.pivotContainer.setPivotIndex(result.pivotIndex);
    this.pivotContainer.setDataIsChange(true);
    this.pivotContainer.setOpts(result.opts);
    this.pivotContainer.draw();
  }

  onToggleCellOpening(result: IOpening) {
    const pivotIndex = this.pivotContainer.getPivotIndex();

    if (result.onRow) {
      pivotIndex.rowsUniques = result.newUniques;
      pivotIndex.maxOpenedFieldsOnRow = result.maxOpenedFields;
      pivotIndex.openedRows = result.newOpened;
      pivotIndex.allRowsLength = result.allUniquesLength;
    } else {
      pivotIndex.columnsUniques = result.newUniques;
      pivotIndex.maxOpenedFieldsOnColumn = result.maxOpenedFields;
      pivotIndex.openedColumns = result.newOpened;
      pivotIndex.allColumnsLength = result.allUniquesLength;
    }
    pivotIndex.valuesTreeMap = result.newValuesTreeMap;
    pivotIndex.codeToDesc = result.newCodeToDesc;

    this.pivotContainer.setDataIsChange(true);
    this.pivotContainer.draw();
  }

  onGetPivotChunk(result: IPivotChunkIndex) {
    const pivotIndex = this.pivotContainer.getPivotIndex();

    pivotIndex.rowsUniques = result.rowsUniques;
    pivotIndex.columnsUniques = result.columnsUniques;
    pivotIndex.valuesTreeMap = result.valuesTreeMap;
    pivotIndex.codeToDesc = result.codeToDesc;
    pivotIndex.openedRows = result.openedRows;
    pivotIndex.openedColumns = result.openedColumns;
    pivotIndex.maxOpenedFieldsOnRow = result.maxOpenedFieldsOnRow;
    pivotIndex.maxOpenedFieldsOnColumn = result.maxOpenedFieldsOnColumn;
    pivotIndex.allRowsLength = result.allRowsLength;
    pivotIndex.allColumnsLength = result.allColumnsLength;
    pivotIndex.rowRange = result.rowRange;
    pivotIndex.columnRange = result.columnRange;

    this.pivotContainer
      .getChunkManager()
      .setExtremes(result.columnRange[0], result.rowRange[0], result.columnRange[1], result.rowRange[1]);
    this.pivotContainer.setDataIsChange(true);
    this.pivotContainer.draw();
  }
}
