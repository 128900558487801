<template>
  <vue-recaptcha
    sitekey="6LfmiOUdAAAAAMqWP7ZrJIbpi_5DsXufuD41Abxr"
    size="invisible"
    theme="light"
    @verify="recaptchaVerified"
    @expire="recaptchaExpired"
    ref="vueRecaptcha"
  >
  </vue-recaptcha>
  <div v-show="!loginState.captchaOk" class="error">
    {{ getRecaptchaErrorMessage() }}
  </div>
</template>

<script>
import vueRecaptcha from "vue3-recaptcha2";
import { INVALID_RECAPTCHA } from "../../core/common/utils/ResourceStrings";
import { manageSubscription } from "../common/SubscriptionManager";
import { defineComponent, inject } from "vue";
import { LOGIN_PLOC_KEY } from "../common/GUIConsts";

export default defineComponent({
  name: "app",
  components: {
    vueRecaptcha,
  },

  setup() {
    const loginPLOC = inject(LOGIN_PLOC_KEY);
    const loginState = manageSubscription(loginPLOC);

    const getRecaptchaErrorMessage = () => {
      return INVALID_RECAPTCHA;
    };

    const recaptchaVerified = async (token) => {
      await loginPLOC.doRecaptcha(token);
    };

    const recaptchaExpired = () => {
      this.$refs.vueRecaptcha.value.reset();
      loginPLOC.recaptchaExpired();
    };

    return {
      loginState,
      getRecaptchaErrorMessage,
      recaptchaVerified,
      recaptchaExpired,
    };
  },
});
</script>
