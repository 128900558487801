export class TooltipManager {
  private tooltipDOM: HTMLElement;
  static TOOLTIP_DISTANCE = 5;

  constructor(tooltipDOM: HTMLElement) {
    this.tooltipDOM = tooltipDOM;
  }

  public show(target: HTMLElement, content: string) {
    this.tooltipDOM.textContent = content;

    const targetRect = target.getBoundingClientRect();
    const tooltipRect = this.tooltipDOM.getBoundingClientRect();

    const spaceAbove = targetRect.top - TooltipManager.TOOLTIP_DISTANCE;
    const spaceRightIncludingTarget = window.innerWidth - targetRect.left - TooltipManager.TOOLTIP_DISTANCE;
    const spaceBelow = window.innerHeight - targetRect.bottom - TooltipManager.TOOLTIP_DISTANCE;

    const spaceLeft = targetRect.left - TooltipManager.TOOLTIP_DISTANCE;
    const spaceRightExcludingTarget = window.innerWidth - targetRect.right - TooltipManager.TOOLTIP_DISTANCE;

    // Decide la posizione in base allo spazio disponibile
    if (spaceAbove > tooltipRect.height && spaceRightIncludingTarget > tooltipRect.width) {
      this.tooltipDOM.style.top =
        targetRect.top - this.tooltipDOM.offsetHeight - TooltipManager.TOOLTIP_DISTANCE + "px";
      this.tooltipDOM.style.left = targetRect.left - TooltipManager.TOOLTIP_DISTANCE + "px";
    } else if (spaceBelow > tooltipRect.height && spaceRightIncludingTarget > tooltipRect.width) {
      this.tooltipDOM.style.top = targetRect.bottom + TooltipManager.TOOLTIP_DISTANCE + "px";
      this.tooltipDOM.style.left = targetRect.left - TooltipManager.TOOLTIP_DISTANCE + "px";
    } else if (spaceLeft > tooltipRect.width) {
      this.tooltipDOM.style.left =
        targetRect.left - this.tooltipDOM.offsetWidth - TooltipManager.TOOLTIP_DISTANCE + "px";
      this.tooltipDOM.style.top = targetRect.top + "px";
    } else if (spaceRightExcludingTarget > tooltipRect.width) {
      this.tooltipDOM.style.left = targetRect.right + TooltipManager.TOOLTIP_DISTANCE + "px";
      this.tooltipDOM.style.top = targetRect.top + "px";
    } else {
      this.tooltipDOM.style.left = "50%";
      this.tooltipDOM.style.top = "50%";
    }

    this.tooltipDOM.style.visibility = "visible";
    this.tooltipDOM.style.opacity = "1";
  }

  public hide() {
    this.tooltipDOM.style.visibility = "hidden";
    this.tooltipDOM.style.opacity = "0";
  }
}
