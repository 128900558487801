import { castCodeValue } from "./PivotUtils";
import {
  EMPLOYEES_ID_SEPARATOR,
  FULL_USER_FORMAT,
  INITIALS_USER_FORMAT,
  LONG_DATE_FORMAT,
  LONG_MONTH_DATE_FORMAT,
  LONG_MONTH_YEAR_DATE_FORMAT,
  MONTH_2DIGIT_YEAR_DATE_FORMAT,
  ONLY_NAME_USER_FORMAT,
  ONLY_SURNAME_USER_FORMAT,
  SHORT_MONTH_DATE_FORMAT,
  SHORT_MONTH_YEAR_DATE_FORMAT,
  TRUNCATED_SURNAME_USER_FORMAT,
} from "./PivotConsts";
import PivotFormatter from "../domain/PivotFormatter";
import { UserDS } from "../../users/domain/UserDS";
import PerformanceDS from "../../performances/domain/PerformanceDS";
import { INumberFormatOptions } from "../domain/PivotOptionsDS";

export default class PivotFormatterImpl extends PivotFormatter {
  private usersMap: Map<string, UserDS>;
  private performancesMap: Map<string, PerformanceDS>;

  constructor(usersMap: Map<string, UserDS>, performancesMap: Map<string, PerformanceDS>) {
    super();
    this.usersMap = usersMap;
    this.performancesMap = performancesMap;
  }

  private internalFormatDate(dateObj: Date, format: string) {
    switch (format) {
      case LONG_DATE_FORMAT:
        return dateObj.toLocaleDateString();
      case SHORT_MONTH_DATE_FORMAT:
        return dateObj.toLocaleString(undefined, {
          month: "short",
          day: "numeric",
          year: "numeric",
        });
      case LONG_MONTH_DATE_FORMAT:
        return dateObj.toLocaleString(undefined, {
          month: "long",
          day: "numeric",
          year: "numeric",
        });
      case MONTH_2DIGIT_YEAR_DATE_FORMAT:
        // eslint-disable-next-line no-case-declarations
        const monthMM = dateObj.toLocaleDateString(undefined, {
          month: "2-digit",
        });
        return `${monthMM} - ${dateObj.getFullYear()}`;
      case SHORT_MONTH_YEAR_DATE_FORMAT:
        // eslint-disable-next-line no-case-declarations
        const monthShort = dateObj.toLocaleDateString(undefined, {
          month: "short",
        });
        return `${monthShort} - ${dateObj.getFullYear()}`;
      case LONG_MONTH_YEAR_DATE_FORMAT:
        // eslint-disable-next-line no-case-declarations
        const monthLong = dateObj.toLocaleDateString(undefined, {
          month: "long",
        });
        return `${monthLong} - ${dateObj.getFullYear()}`;

      default:
        return new Date(dateObj).toLocaleDateString();
    }
  }

  private internalFormatUser(userId: string, format: string) {
    const userObj = this.usersMap.get(userId);
    const userName = userObj?.name || "utente non trovato";
    const userSurname = userObj?.surname || "";

    switch (format) {
      case FULL_USER_FORMAT:
        return `${userName} ${userSurname}`;
      case ONLY_NAME_USER_FORMAT:
        return userName;
      case ONLY_SURNAME_USER_FORMAT:
        return userSurname;
      case TRUNCATED_SURNAME_USER_FORMAT:
        return `${userName} ${userSurname[0]}.`;
      case INITIALS_USER_FORMAT:
        return `${userName[0]}. ${userSurname[0]}.`;
      default:
        return "non definito";
    }
  }

  public formatDimension(codeStr: string, entityAlias: string, entityFormat: string) {
    switch (entityAlias) {
      case "D_DATE":
        // eslint-disable-next-line no-case-declarations
        const value = castCodeValue(codeStr, entityAlias) as Date;
        return this.internalFormatDate(value, entityFormat);
      case "D_CUSTOMER":
        return this.internalFormatUser(codeStr, entityFormat);
      case "D_EMPLOYEES":
        // eslint-disable-next-line no-case-declarations
        const employees = codeStr.split(EMPLOYEES_ID_SEPARATOR);
        // eslint-disable-next-line no-case-declarations
        let description = "";
        employees.forEach((id) => {
          const value = this.internalFormatUser(id, entityFormat);
          description += description === "" ? value : ` ${value}`;
        });
        return description;
      case "D_PERFORMANCE":
        return this.performancesMap.get(codeStr)?.name || "performance non trovata";
      default:
        return "non definito";
    }
  }

  public formatNumber(options: INumberFormatOptions, value: number) {
    let result = value + "";

    options = options || {};

    if (options.decimals >= 0 && options.decimals <= 20) {
      result = value.toFixed(options.decimals);
    }

    if (options.precision >= 1 && options.precision <= 21) {
      result = (+result).toPrecision(options.precision);
    }

    if (options.decimalSeparator !== ".") {
      result = result.replace(".", options.decimalSeparator);
    }

    if (options.groupSeparator) {
      let index = result.lastIndexOf(options.decimalSeparator);
      index = index > -1 ? index : result.length;
      let newOutput = result.substring(index);
      let i = index;

      while (i--) {
        if ((index - i - 1) % 3 === 0 && i !== index - 1) {
          newOutput = options.groupSeparator + newOutput;
        }
        newOutput = result.charAt(i) + newOutput;
      }

      result = newOutput;
    }

    if (options.prefix) {
      result = options.prefix + result;
    }
    if (options.suffix) {
      result += options.suffix;
    }

    result = result.replace(`-${options.groupSeparator}`, "-");

    return result;
  }
}
