import { HTTPClient, HTTPMethod, HTTPResponse } from "@/core/common/communication/HTTPClient";
import { INVALID_CREDENTIALS } from "@/core/common/utils/ResourceStrings";
import { Login } from "../domain/Login";
import { LoginResponse } from "../domain/LoginResponse";
import { RecaptchaResponse } from "../domain/RecaptchaResponse";

interface APILoginResponse {
  success: boolean;
  errorMsg: string;
  data: { userId: string; success: boolean };
}

export class LoginImpl extends Login {
  private httpClient: HTTPClient;
  constructor(httpClient: HTTPClient) {
    super();
    this.httpClient = httpClient;
  }

  public async doLogout(): Promise<boolean> {
    const httpResponse: HTTPResponse = await this.httpClient.send(HTTPMethod.POST, "/api/logout", {});

    return Promise.resolve(httpResponse.success);
  }

  public async doLogin(mail: string, password: string): Promise<LoginResponse> {
    const httpResponse: HTTPResponse = await this.httpClient.send(HTTPMethod.POST, "/api/login", { mail, password });
    const result = {} as LoginResponse;
    if (httpResponse.success && (<APILoginResponse>httpResponse).data.success) {
      result.success = true;
      result.errorMsg = "";
      result.userId = (<APILoginResponse>httpResponse).data.userId;
    } else {
      result.success = false;
      result.errorMsg = httpResponse.success ? INVALID_CREDENTIALS : httpResponse.errorMsg;
    }

    return Promise.resolve(result);
  }

  public async doRecaptcha(token: string): Promise<RecaptchaResponse> {
    const httpResponse = await this.httpClient.send(HTTPMethod.POST, "/api/verifyCaptcha", {
      captchaToken: token,
    });
    if (httpResponse.success) return Promise.resolve({ success: true, errorMsg: "" });
    else
      return Promise.resolve({
        success: false,
        errorMsg: httpResponse.errorMsg,
      });
  }
}
