import { CELL_HEIGHT, COLUMN_WIDTH } from "../common/PivotConsts";

export default class PivotScroller {
  private pivotScrollerDOM: HTMLElement;
  private firstRowAbs: number;
  private firstColumnAbs: number;
  private newRow: boolean;
  private newColumn: boolean;
  private translateRow: number;
  private translateColumn: number;

  constructor(pivotScrollerDOM: HTMLElement) {
    this.pivotScrollerDOM = pivotScrollerDOM;
    this.firstRowAbs = 0;
    this.firstColumnAbs = 0;
    this.newRow = false;
    this.newColumn = false;
    this.translateRow = 0;
    this.translateColumn = 0;
  }

  public getFirstRowAbs(): number {
    return this.firstRowAbs;
  }

  public getFirstColumnAbs(): number {
    return this.firstColumnAbs;
  }

  public isNewRow(): boolean {
    return this.newRow;
  }

  public isNewColumn(): boolean {
    return this.newColumn;
  }

  public getTranslateRow(): number {
    return this.translateRow;
  }

  public getTranslateColumn(): number {
    return this.translateColumn;
  }

  calculateScrollInfo() {
    this.newRow = false;
    this.newColumn = false;

    const currentScrollTop = this.pivotScrollerDOM.scrollTop;
    const currentScrollLeft = this.pivotScrollerDOM.scrollLeft;

    const firstRowAbs = currentScrollTop < 0 ? 0 : Math.floor(currentScrollTop / CELL_HEIGHT);
    const firstColumnAbs = currentScrollLeft < 0 ? 0 : Math.floor(currentScrollLeft / COLUMN_WIDTH);

    if (firstRowAbs !== this.firstRowAbs) {
      this.firstRowAbs = firstRowAbs;
      this.newRow = true;
    }

    if (this.firstColumnAbs !== firstColumnAbs) {
      this.firstColumnAbs = firstColumnAbs;
      this.newColumn = true;
    }

    this.translateRow = Math.ceil(-currentScrollTop % CELL_HEIGHT);
    this.translateColumn = Math.ceil(-currentScrollLeft % COLUMN_WIDTH);
  }
}
