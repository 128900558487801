import { Login } from "./Login";
import { LoginResponse } from "./LoginResponse";
import { RecaptchaResponse } from "./RecaptchaResponse";
import { User } from "../../users/domain/User";

export default class DoLoginUseCase {
  static async doLogin(login: Login, mail: string, password: string, captchaOk: boolean): Promise<LoginResponse> {
    if (User.checkMail(mail) && captchaOk) {
      const response = await login.doLogin(mail, password);
      if (response.success) localStorage.setItem(Login.LOGGED_USER_KEY, response.userId);
      return response;
    } else
      return Promise.resolve({
        success: false,
        errorMsg: "",
        userId: "",
        user: {} as User,
      });
  }

  static async doRecaptcha(login: Login, token: string): Promise<RecaptchaResponse> {
    return await login.doRecaptcha(token);
  }

  static tryLoadLoggedUser(): string {
    return localStorage.getItem(Login.LOGGED_USER_KEY) as string;
  }
}
