<template>
  <div class="signin-wrapper">
    <div class="card-signin">
      <span class="app-logo"><span class="text-uppercase">BS</span>chedoole </span>
      <div class="signin-header">
        <h2>Benvenuto!</h2>
        <h4>Esegui il login per continuare</h4>
        <div class="form-group">
          <label>Email</label>
          <input type="text" class="form-control-large" placeholder="Inserisci la tua mail" v-model="loginState.mail" />
          <div class="error" v-show="loginState.loginTried && !mailOk">
            {{ getInvalidMailMessage() }}
          </div>
        </div>
        <div class="form-group">
          <label>Password</label>
          <input
            type="password"
            class="form-control-large"
            placeholder="Inserisci la tua password"
            v-model="loginState.password"
          />
        </div>
        <div class="error" v-show="loginState.loginTried && !loginState.loginOk">
          {{ loginState.loginErrorMsg }}
        </div>
        <input type="button" value="Login" @click="doLogin()" class="btn btn-secondary" />
        <Recaptcha></Recaptcha>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, inject, computed } from "vue";
import { manageSubscription } from "../common/SubscriptionManager";
import { LOGIN_PLOC_KEY, APP_PLOC_KEY } from "../common/GUIConsts";
import { INVALID_MAIL } from "../../core/common/utils/ResourceStrings";
import Recaptcha from "./Recaptcha.vue";
import { AppPLOC } from "../../core/app-navigation/presentation/AppPLOC";
import { User } from "@/core/users/domain/User";
import { LoginPLOC } from "@/core/login/presentation/LoginPLOC";

export default defineComponent({
  components: { Recaptcha },
  setup() {
    const loginPLOC = inject(LOGIN_PLOC_KEY) as LoginPLOC;
    const loginState = manageSubscription(loginPLOC);

    const appPLOC = inject(APP_PLOC_KEY) as AppPLOC;

    const doLogin = async function () {
      await loginPLOC.doLogin();
      appPLOC.onLogin(loginState.value.loginOk);
    };

    const mailOk = computed(() => {
      return User.checkMail(loginState.value.mail);
    });

    const getInvalidMailMessage = function () {
      return INVALID_MAIL;
    };

    return { loginState, doLogin, mailOk, getInvalidMailMessage };
  },
});
</script>

<style lang="scss">
@import "./public/styles/variables.scss";

.signin-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.card-signin {
  display: flex;
  flex-direction: column;
  background-color: rgba(#fff, 0.5);
  align-items: flex-start;

  @media (min-width: 480px) {
    border: 1px solid $gray-300;
    padding: 30px 35px;
  }
}

.app-logo {
  color: #3366ff;
  font-weight: 500;
  font-size: 28px;
  text-transform: lowercase;
  letter-spacing: -1px;

  .text-uppercase {
    text-transform: uppercase;
  }
}

.signin-header {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  h2 {
    font-weight: 500;
    color: $color-primary;
    letter-spacing: -1px;
    margin-bottom: 5px;
  }

  h4 {
    margin-top: 0px;
    font-weight: 400;
    color: $gray-900;
    margin-bottom: 25px;
    text-align: left;
  }

  label {
    color: $gray-600;
    margin-bottom: 5px;
    font-size: 0.8em;
  }
  .btn {
    margin-top: 25px;
  }

  .form-group {
    .error {
      margin-top: 10px;
    }
  }
}
</style>
