import { TOGGLE_CELL_OPENING_MSG } from "../common/PivotConsts";
import IWorkerPostMessage from "../worker/IWorkerPostMessage";
import { TooltipManager } from "./TooltipManager";

export default class DomEventsManager {
  private worker: IWorkerPostMessage;
  private tooltipManager: TooltipManager;

  constructor(worker: IWorkerPostMessage, tooltipManager: TooltipManager) {
    this.worker = worker;
    this.tooltipManager = tooltipManager;
  }

  handleClick(event: Event) {
    let interactiveElement = false;
    let el: HTMLElement | null = (event?.target as HTMLElement) || null;
    let onRow;
    let headPath;
    while (el?.nodeType === 1 && !interactiveElement) {
      const cellType = el.getAttribute("data-cell-type");
      switch (cellType) {
        case "rowHead":
          interactiveElement = true;
          onRow = true;
          headPath = el.getAttribute("data-head-path");
          break;
        case "columnHead":
          interactiveElement = true;
          onRow = false;
          headPath = el.getAttribute("data-head-path");
          break;
        default:
          el = el.parentNode as HTMLElement;
      }

      if (interactiveElement && !!headPath) {
        this.worker.postMessage({
          type: TOGGLE_CELL_OPENING_MSG,
          props: { onRow, headPath },
        });
      }
    }
  }

  handleMouseOver(event: Event) {
    let el: HTMLElement | null = (event?.target as HTMLElement) || null;
    let interactiveElement = false;

    while (el?.nodeType === 1 && !interactiveElement) {
      const cellType = el.getAttribute("data-cell-type");
      if (cellType === "rowHead" || cellType === "columnHead") {
        interactiveElement = true;
        if (el.classList.contains("need-tooltip")) this.tooltipManager.show(el, el.textContent || "");
        else this.tooltipManager.hide();
      } else {
        el = el.parentNode as HTMLElement;
        this.tooltipManager.hide();
      }
    }
  }
}
