import { CHUNK_SIZE_FACTOR, GET_PIVOT_CHUNK_MSG } from "../common/PivotConsts";
import IWorkerPostMessage from "../worker/IWorkerPostMessage";

interface IChunkRequest {
  rowStart: number;
  rowEnd: number;
  columnStart: number;
  columnEnd: number;
}
export default class ChunkManager {
  private chunkRowSentinel: number;
  private chunkRowSize: number;
  private chunkColumnSentinel: number;
  private chunkColumnSize: number;
  private chunkMidpoint: Array<number>;
  private chunkStartRow: number;
  private chunkEndRow: number;
  private chunkStartColumn: number;
  private chunkEndColumn: number;
  private lastPivotChunkRequest: IChunkRequest | null;
  private worker: IWorkerPostMessage;

  constructor(worker: IWorkerPostMessage) {
    this.chunkRowSentinel = 0;
    this.chunkRowSize = 0;
    this.chunkColumnSentinel = 0;
    this.chunkColumnSize = 0;
    this.chunkMidpoint = [];
    this.chunkStartRow = 0;
    this.chunkEndRow = 0;
    this.chunkStartColumn = 0;
    this.chunkEndColumn = 0;
    this.lastPivotChunkRequest = {} as IChunkRequest;
    this.worker = worker;
  }

  public resetChunk() {
    this.chunkStartRow = 0;
    this.chunkEndRow = 0;
    this.chunkStartColumn = 0;
    this.chunkEndColumn = 0;
  }

  public getChunkEndColumn(): number {
    return this.chunkEndColumn;
  }

  public init(rowSentinel: number, rowSize: number, columnSentinel: number, columnSize: number) {
    this.chunkRowSentinel = rowSentinel;
    this.chunkRowSize = rowSize * CHUNK_SIZE_FACTOR;
    this.chunkColumnSentinel = columnSentinel;
    this.chunkColumnSize = columnSize * CHUNK_SIZE_FACTOR;
  }

  public setExtremes(startColumn: number, startRow: number, endColumn: number, endRow: number) {
    this.chunkStartRow = startRow;
    this.chunkEndRow = endRow;
    this.chunkStartColumn = startColumn;
    this.chunkEndColumn = endColumn;
  }

  public calculateChunk(
    firstRowIndex: number,
    firstColumnIndex: number,
    nRowsDOM: number,
    nColumnsDOM: number,
    nAllRows: number,
    nAllColumns: number
  ) {
    let requestchunkStartRow = this.chunkStartRow;
    let requestchunkEndRow = this.chunkEndRow;
    let requestchunkStartColumn = this.chunkStartColumn;
    let requestchunkEndColumn = this.chunkEndColumn;

    this.chunkMidpoint[0] = firstRowIndex + Math.ceil(nRowsDOM / 2);
    this.chunkMidpoint[1] = firstColumnIndex + Math.ceil(nColumnsDOM / 2);

    let requestChunk = false;
    if (
      (this.chunkEndRow < nAllRows && this.chunkEndRow - this.chunkMidpoint[0] < this.chunkRowSentinel) ||
      (this.chunkStartRow > 0 && this.chunkMidpoint[0] - this.chunkStartRow < this.chunkRowSentinel)
    ) {
      requestChunk = true;
    }
    if (
      (this.chunkEndColumn < nAllColumns && this.chunkEndColumn - this.chunkMidpoint[1] < this.chunkColumnSentinel) ||
      (this.chunkStartColumn > 0 && this.chunkMidpoint[1] - this.chunkStartColumn < this.chunkColumnSentinel)
    ) {
      requestChunk = true;
    }

    if (requestChunk) {
      requestchunkStartRow = this.chunkMidpoint[0] - this.chunkRowSize;
      if (requestchunkStartRow < 0) {
        requestchunkStartRow = 0;
      }
      requestchunkEndRow = this.chunkMidpoint[0] + this.chunkRowSize;
      if (requestchunkEndRow > nAllRows) {
        requestchunkEndRow = nAllRows;
      }

      requestchunkStartColumn = this.chunkMidpoint[1] - this.chunkColumnSize;
      if (requestchunkStartColumn < 0) {
        requestchunkStartColumn = 0;
      }
      requestchunkEndColumn = this.chunkMidpoint[1] + this.chunkColumnSize;
      if (requestchunkEndColumn > nAllColumns) {
        requestchunkEndColumn = nAllColumns;
      }

      this.lastPivotChunkRequest = {
        rowStart: requestchunkStartRow,
        rowEnd: requestchunkEndRow,
        columnStart: requestchunkStartColumn,
        columnEnd: requestchunkEndColumn,
      };
    }
  }

  public getPivotChunk = () => {
    if (this.lastPivotChunkRequest && !this.worker.isWaitingForPivotChunk()) {
      this.worker.postMessage({
        type: GET_PIVOT_CHUNK_MSG,
        props: this.lastPivotChunkRequest,
      });
      this.lastPivotChunkRequest = null;
      this.worker.setWaitingForPivotChunk(true);
    }
  };

  public getChunkStartRow() {
    return this.chunkStartRow;
  }
  public getChunkStartColumn() {
    return this.chunkStartColumn;
  }

  public getChunkEndRow() {
    return this.chunkEndRow;
  }
}
