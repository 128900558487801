import { CELL_HEIGHT, CONTAINER_RESIZE_THRESHOLD } from "../common/PivotConsts";
import { debounce } from "../common/PivotUtils";
import { IPivotChunkIndex } from "../domain/PivotDS";
import { IPivotOptions } from "../domain/PivotOptionsDS";
import CellWidthCalculator from "./CellWidthCalculator";
import ChunkManager from "./ChunkManager";
import DomEventsManager from "./DomEventsManager";
import DimensionsDescrHead from "./DimensionsDescrHead";
import PivotColumnsHead from "./PivotColumnsHead";
import PivotDataGrid from "./PivotDataGrid";
import PivotRowsHead from "./PivotRowsHead";
import PivotScroller from "./PivotScroller";

interface IInputParams {
  pivotIndex: IPivotChunkIndex;
  dataIsChange: boolean;
  opts: IPivotOptions;
}

export default class PivotContainer {
  private pivotContainerDOM: HTMLElement;
  private dimensionsDescrHead: DimensionsDescrHead;
  private pivotRowsHead: PivotRowsHead;
  private pivotDrawAreaDOM: HTMLElement;
  private pivotGridDOM: HTMLElement;
  private pivotColumnsHeadDOM: HTMLElement;
  private pivotScrollerDOM: HTMLElement;
  private pivotHeaderRowScrollerDOM: HTMLElement;
  private pivotHeaderColumnScrollerDOM: HTMLElement;
  private pivotScroller: PivotScroller;
  private pivotDataGrid: PivotDataGrid;
  private pivotColumnsHead: PivotColumnsHead;
  private chunkManager: ChunkManager;
  private currentHeight: number;
  private currentWidth: number;
  private isSizeChanged: boolean;
  private hasSpaceForDraw: boolean;
  private observersInitialized: boolean;
  private params: IInputParams;
  private isAnim: boolean;
  private timerStopRender: number;
  private scrollMaster: string;
  private domEventsManager: DomEventsManager;
  private cellWidthCalculator: CellWidthCalculator;

  constructor(pivotContainerDOM: HTMLElement) {
    this.pivotContainerDOM = pivotContainerDOM;
    this.dimensionsDescrHead = {} as DimensionsDescrHead;
    this.pivotDrawAreaDOM = this.pivotContainerDOM.querySelector(".pivot-draw-area") || new HTMLElement();
    this.pivotGridDOM = this.pivotContainerDOM.querySelector(".pivot-grid-data") || new HTMLElement();
    this.pivotColumnsHeadDOM = this.pivotContainerDOM.querySelector(".pivot-header-column-grid") || new HTMLElement();
    this.pivotScrollerDOM = this.pivotContainerDOM.querySelector(".pivot-scroller") || new HTMLElement();
    this.pivotHeaderRowScrollerDOM =
      this.pivotContainerDOM.querySelector(".pivot-header-row-scroller") || new HTMLElement();
    this.pivotHeaderColumnScrollerDOM =
      this.pivotContainerDOM.querySelector(".pivot-header-column-scroller") || new HTMLElement();
    const cellWidthCalculatorDOM: HTMLElement =
      this.pivotContainerDOM.querySelector("#cellWidthCalculator") || new HTMLElement();
    this.cellWidthCalculator = new CellWidthCalculator(cellWidthCalculatorDOM, "600 14px Montserrat, sans-serif");
    this.pivotScroller = new PivotScroller(this.pivotScrollerDOM);
    this.pivotDataGrid = new PivotDataGrid(this.pivotGridDOM);
    this.pivotColumnsHead = new PivotColumnsHead(this.pivotColumnsHeadDOM, this.cellWidthCalculator);
    this.pivotRowsHead = new PivotRowsHead(
      this.pivotContainerDOM.querySelector(".pivot-header-row-grid") || new HTMLElement(),
      this.cellWidthCalculator
    );
    this.chunkManager = {} as ChunkManager;
    this.domEventsManager = {} as DomEventsManager;
    this.currentHeight = 0;
    this.currentWidth = 0;
    this.isSizeChanged = false;
    this.hasSpaceForDraw = false;
    this.observersInitialized = false;
    this.isAnim = false;
    this.timerStopRender = -1;
    this.scrollMaster = "";
    this.params = {
      pivotIndex: {} as IPivotChunkIndex,
      opts: {} as IPivotOptions,
      dataIsChange: false,
    };
  }

  public getPivotIndex() {
    return this.params.pivotIndex;
  }

  public getChunkManager() {
    return this.chunkManager;
  }

  public setDataIsChange(value: boolean) {
    this.params.dataIsChange = value;
  }

  public setPivotIndex(value: IPivotChunkIndex) {
    this.params.pivotIndex = value;
  }

  public setOpts(value: IPivotOptions) {
    this.params.opts = value;
  }

  debounceDraw = debounce(() => {
    this.draw();
  }, 150);

  public init(
    opts: IPivotOptions,
    clickManager: DomEventsManager,
    chunkManager: ChunkManager,
    dimensionsDescrHead: DimensionsDescrHead
  ) {
    this.params = {
      pivotIndex: {} as IPivotChunkIndex,
      dataIsChange: false,
      opts,
    };
    this.chunkManager = chunkManager;
    this.domEventsManager = clickManager;
    this.dimensionsDescrHead = dimensionsDescrHead;

    this.initObservers();
    this.attachScrollEvents();
    this.attachClickEvent();
  }

  attachClickEvent() {
    this.pivotContainerDOM.addEventListener(
      "click",
      (e) => {
        this.domEventsManager.handleClick(e);
      },
      {
        capture: true,
      }
    );

    this.pivotContainerDOM.addEventListener(
      "mouseover",
      (e) => {
        this.domEventsManager.handleMouseOver(e);
      },
      {
        capture: true,
      }
    );
  }

  private startDataScrollAnimation = () => {
    if (!this.isAnim) {
      this.isAnim = true;
      window.requestAnimationFrame(this.fillTemplateWithData.bind(this));
    }
  };

  private stopDataScrollAnimation = () => {
    this.isAnim = false;
    this.resetScrollMaster();
  };

  private resetScrollMaster() {
    this.scrollMaster = "";
  }

  private attachScrollEvents() {
    this.pivotScrollerDOM.addEventListener(
      "scroll",
      () => {
        this.startDataScrollAnimation();
        clearTimeout(this.timerStopRender);
        this.timerStopRender = window.setTimeout(this.stopDataScrollAnimation, 100);
        if (!this.scrollMaster || this.scrollMaster === "data") {
          this.scrollMaster = "data";
          this.pivotHeaderRowScrollerDOM.scrollTop = this.pivotScrollerDOM.scrollTop;
          this.pivotHeaderColumnScrollerDOM.scrollLeft = this.pivotScrollerDOM.scrollLeft;
        }
      },
      { passive: true }
    );

    this.pivotHeaderRowScrollerDOM.addEventListener(
      "scroll",
      () => {
        if (!this.scrollMaster || this.scrollMaster === "headerRow") {
          this.scrollMaster = "headerRow";
          this.pivotScrollerDOM.scrollTop = this.pivotHeaderRowScrollerDOM.scrollTop;
        }
      },
      { passive: true }
    );

    this.pivotHeaderColumnScrollerDOM.addEventListener(
      "scroll",
      () => {
        if (!this.scrollMaster || this.scrollMaster === "headerColumn") {
          this.scrollMaster = "headerColumn";
          this.pivotScrollerDOM.scrollLeft = this.pivotHeaderColumnScrollerDOM.scrollLeft;
        }
      },
      { passive: true }
    );

    this.pivotScrollerDOM.addEventListener(
      "touchstart",
      () => {
        this.pivotScrollerDOM.scrollTop = this.pivotHeaderRowScrollerDOM.scrollTop;
        this.resetScrollMaster();
      },
      { passive: true }
    );

    this.pivotHeaderRowScrollerDOM.addEventListener(
      "touchstart",
      () => {
        this.pivotScrollerDOM.scrollTop = this.pivotHeaderRowScrollerDOM.scrollTop;
        this.resetScrollMaster();
      },
      { passive: true }
    );

    this.pivotHeaderColumnScrollerDOM.addEventListener(
      "touchstart",
      () => {
        this.pivotScrollerDOM.scrollLeft = this.pivotHeaderColumnScrollerDOM.scrollLeft;
        this.resetScrollMaster();
      },
      { passive: true }
    );

    this.pivotScrollerDOM.addEventListener("mouseleave", this.resetScrollMaster, { passive: true });
    this.pivotHeaderRowScrollerDOM.addEventListener("mouseleave", this.resetScrollMaster, { passive: true });
    this.pivotHeaderColumnScrollerDOM.addEventListener("mouseleave", this.resetScrollMaster, { passive: true });
  }

  private initObservers() {
    if (!this.observersInitialized) {
      this.initResizeObserver();
      this.observersInitialized = true;
    }
  }

  private initResizeObserver() {
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries[0].contentRect.width > 0 && entries[0].contentRect.height > 0) {
        const widthInt = Math.floor(entries[0].contentRect.width);
        const heightInt = Math.floor(entries[0].contentRect.height);

        if (
          Math.abs(this.currentWidth - widthInt) > CONTAINER_RESIZE_THRESHOLD ||
          Math.abs(this.currentHeight - heightInt) > CONTAINER_RESIZE_THRESHOLD
        ) {
          this.currentWidth = widthInt;
          this.currentHeight = heightInt;
          this.isSizeChanged = true;
        }
        this.hasSpaceForDraw = true;
      } else {
        this.hasSpaceForDraw = false;
      }
      this.debounceDraw();
    });

    resizeObserver.observe(this.pivotContainerDOM);
  }

  private canDraw() {
    return this.hasSpaceForDraw && this.params.pivotIndex?.valuesTreeMap;
  }

  private DOMSizingChanged() {
    return (
      this.pivotDataGrid.isColumnsChanged() ||
      this.pivotDataGrid.isRowsChanged() ||
      this.pivotColumnsHead.isRowsChanged() ||
      this.pivotRowsHead.isColumnsChanged()
    );
  }

  private calculateSizings(showColumnDimsDescrHead: boolean) {
    this.pivotDataGrid.calculateFullSizing(this.params.pivotIndex);

    this.pivotColumnsHead.calculateHeight(
      this.params.pivotIndex?.maxOpenedFieldsOnColumn || 0,
      this.currentHeight,
      this.pivotDataGrid.getDataGridFullHeight(),
      showColumnDimsDescrHead
    );

    this.pivotRowsHead.calculateWidth(
      this.params.pivotIndex?.maxOpenedFieldsOnRow || 0,
      this.params.opts,
      this.pivotDataGrid.getDataGridFullWidth(),
      this.currentWidth
    );

    this.pivotDataGrid.calculateDOMSizing(
      this.pivotRowsHead.getDomWidth(),
      this.pivotColumnsHead.getDomHeight(),
      this.currentWidth,
      this.currentHeight
    );

    this.pivotDataGrid.calculateTotalsPositioning(this.params.opts);
  }

  private setCSSSizingProps() {
    this.pivotContainerDOM.style.setProperty("--row-head-width", `${this.pivotRowsHead.getDomWidth()}px`);
    this.pivotContainerDOM.style.setProperty("--row-head-height", `${this.pivotColumnsHead.getDomHeight()}px`);
    this.pivotDrawAreaDOM.style.setProperty("width", `${this.currentWidth}px`);
    this.pivotDrawAreaDOM.style.setProperty("height", `${this.currentHeight}px`);
  }

  private setCSSAllDataProps(showColumnDimsDescrHead: boolean) {
    this.pivotContainerDOM.style.setProperty("--row-total-n", this.pivotDataGrid.getNAllDataRows().toString());
    this.pivotContainerDOM.style.setProperty("--column-total-n", this.pivotDataGrid.getNAllDataColumns().toString());
    this.pivotContainerDOM.style.setProperty("--cell-height", `${CELL_HEIGHT}px`);
    this.pivotContainerDOM.style.setProperty("--column-total-width", `${this.pivotDataGrid.getDataGridFullWidth()}px`);
    this.pivotContainerDOM.style.setProperty("--template-rows", this.pivotRowsHead.getCSSGridTemplate());
    this.pivotContainerDOM.style.setProperty("--showHeaderColumnDimensionName", (+showColumnDimsDescrHead).toString());
    this.pivotContainerDOM.style.setProperty("--rows-head-full-width", `${this.pivotRowsHead.getFullWidth()}px`);
    this.pivotContainerDOM.style.setProperty(
      "--columns-head-full-height",
      `${this.pivotColumnsHead.getFullHeight()}px`
    );
  }

  private setCSSDomDataProps() {
    this.pivotContainerDOM.style.setProperty("--row-n", this.pivotDataGrid.getNRowsDOM().toString());
    this.pivotContainerDOM.style.setProperty("--column-n", this.pivotDataGrid.getNColumnsDOM().toString());
    this.pivotContainerDOM.style.setProperty("--column-head-n", this.pivotRowsHead.getNColumns().toString());
    this.pivotContainerDOM.style.setProperty("--row-head-n", this.pivotColumnsHead.getNRows().toString());
  }

  private drawTemplate() {
    if (this.canDraw()) {
      if (this.isSizeChanged || this.params.dataIsChange) {
        const showColumnDimsDescrHead = this.dimensionsDescrHead.showColumnDimsHead(this.params.opts);

        this.calculateSizings(showColumnDimsDescrHead);

        this.setCSSSizingProps();

        if (this.params.dataIsChange) {
          this.setCSSAllDataProps(showColumnDimsDescrHead);

          this.dimensionsDescrHead.draw(this.params.opts, this.pivotRowsHead.getNColumns());
        }

        if (this.DOMSizingChanged()) {
          this.setCSSDomDataProps();

          this.chunkManager.init(
            this.pivotDataGrid.getNRowsDOM(),
            this.pivotDataGrid.getNRowsDOM(),
            this.pivotDataGrid.getNColumnsDOM(),
            this.pivotDataGrid.getNColumnsDOM()
          );

          this.pivotDataGrid.drawTemplate();

          this.pivotColumnsHead.drawTemplate(this.pivotDataGrid.getNColumnsDOM());

          this.pivotRowsHead.drawTemplate(this.pivotDataGrid.getNRowsDOM());
        }
      }
      this.pivotDrawAreaDOM.style.setProperty("display", "block");
    } else {
      this.pivotDrawAreaDOM.style.setProperty("display", "none");
    }
  }

  private setCSSTranslateProps() {
    this.pivotDataGrid.setCSSTranslateProp(
      this.pivotScroller.getTranslateRow(),
      this.pivotScroller.getTranslateColumn()
    );
    this.pivotColumnsHead.setCSSTranslateProp(this.pivotScroller.getTranslateColumn());
    this.pivotRowsHead.setCSSTranslateProp(this.pivotScroller.getTranslateRow());
  }

  private fillTemplateWithData() {
    if (this.params.pivotIndex?.valuesTreeMap) {
      this.pivotScroller.calculateScrollInfo();
      this.setCSSTranslateProps();

      if (
        this.pivotScroller.isNewRow() ||
        this.pivotScroller.isNewColumn() ||
        this.params.dataIsChange ||
        this.isSizeChanged
      ) {
        this.chunkManager.calculateChunk(
          this.pivotScroller.getFirstRowAbs(),
          this.pivotScroller.getFirstColumnAbs(),
          this.pivotDataGrid.getNRowsDOM(),
          this.pivotDataGrid.getNColumnsDOM(),
          this.pivotDataGrid.getNAllDataRows(),
          this.pivotDataGrid.getNAllDataColumns()
        );

        this.pivotDataGrid.fillTemplateWithData(
          this.pivotScroller.getFirstRowAbs(),
          this.pivotScroller.getFirstColumnAbs(),
          this.params.pivotIndex,
          this.params.opts,
          this.chunkManager
        );

        if (this.pivotScroller.isNewRow() || this.params.dataIsChange || this.isSizeChanged) {
          this.pivotRowsHead.fillTemplateWithData(
            this.params.opts,
            this.pivotScroller.getFirstRowAbs(),
            this.chunkManager,
            this.params.pivotIndex,
            this.pivotDataGrid.getNRowsDOM()
          );
        }

        if (this.pivotScroller.isNewColumn() || this.params.dataIsChange || this.isSizeChanged) {
          const columnTemplate = this.pivotColumnsHead.calculateColumnTemplate(
            this.pivotDataGrid.getNColumnsDOM(),
            this.pivotScroller.getFirstColumnAbs()
          );
          this.pivotContainerDOM.style.setProperty("--template-columns", columnTemplate);

          this.pivotColumnsHead.fillTemplateWithData(
            this.pivotScroller.getFirstColumnAbs(),
            this.params.pivotIndex,
            this.params.opts,
            this.pivotDataGrid.getNColumnsDOM(),
            this.chunkManager
          );
        }
      }
    }

    this.chunkManager.getPivotChunk();

    if (this.isAnim) {
      window.requestAnimationFrame(this.fillTemplateWithData.bind(this));
    }
  }

  draw() {
    this.drawTemplate();

    if (this.canDraw() && (this.isSizeChanged || this.params.dataIsChange)) {
      this.fillTemplateWithData();

      this.params.dataIsChange = false;
      this.isSizeChanged = false;
    }
  }
}
