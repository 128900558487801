import { COLUMN_WIDTH, VALUES_SEPARATOR } from "../common/PivotConsts";
import { getDescFromCode, valueIsValid } from "../common/PivotUtils";
import { IPivotChunkIndex } from "../domain/PivotDS";
import { IPivotOptions } from "../domain/PivotOptionsDS";
import CellWidthCalculator from "./CellWidthCalculator";
import ChunkManager from "./ChunkManager";

export default class PivotRowsHead {
  private pivotRowsHeadDOM: HTMLElement;
  private rowsHeadCells: Map<string, HTMLElement>;
  private CSSGridTemplate: string;
  private nColumns: number;
  private domWidth: number;
  private fullWidth: number;
  private columnsChanged: boolean;
  private cellWidthCalculator: CellWidthCalculator;

  constructor(pivotRowsHeadDOM: HTMLElement, cellWidthCalculator: CellWidthCalculator) {
    this.pivotRowsHeadDOM = pivotRowsHeadDOM;
    this.rowsHeadCells = new Map();
    this.CSSGridTemplate = "";
    this.nColumns = 0;
    this.domWidth = 0;
    this.fullWidth = 0;
    this.columnsChanged = false;
    this.cellWidthCalculator = cellWidthCalculator;
  }

  public getCSSGridTemplate(): string {
    return this.CSSGridTemplate;
  }

  public getNColumns(): number {
    return this.nColumns;
  }

  public getDomWidth(): number {
    return this.domWidth;
  }

  public getFullWidth(): number {
    return this.fullWidth;
  }

  public isColumnsChanged(): boolean {
    return this.columnsChanged;
  }

  public calculateWidth(
    maxOpenedFieldsOnRow: number,
    opts: IPivotOptions,
    pivotDataFullWidth: number,
    pivotContainerCurrentWidth: number
  ) {
    const nColumns = maxOpenedFieldsOnRow;
    this.columnsChanged = nColumns !== this.nColumns;
    this.nColumns = nColumns;

    this.CSSGridTemplate = "";
    let processedColumnsCount = 0;
    this.domWidth = opts.rows.reduce((accumulator) => {
      let currentValue = 0;
      const measuresColumn = opts.measuresOnColumn ? 0 : 1;
      if (processedColumnsCount < this.nColumns - measuresColumn) {
        currentValue = COLUMN_WIDTH || 0;
        this.CSSGridTemplate += `${currentValue}px `;
        processedColumnsCount++;
      }
      return accumulator + currentValue;
    }, 0);
    if (!opts.measuresOnColumn) {
      const currentValue = COLUMN_WIDTH || 0;
      this.CSSGridTemplate += `${currentValue}px `;
      this.domWidth += currentValue;
    }
    this.fullWidth = this.domWidth;

    const halfWidth = Math.floor(pivotContainerCurrentWidth / 2);
    if (this.domWidth + pivotDataFullWidth > pivotContainerCurrentWidth && this.domWidth > halfWidth) {
      this.domWidth = halfWidth;
      if (pivotDataFullWidth < halfWidth) {
        this.domWidth += halfWidth - pivotDataFullWidth;
      }
    }
  }

  public setCSSTranslateProp(translateRow: number) {
    this.pivotRowsHeadDOM.style.setProperty("transform", `translate(0px,${translateRow}px)`);
  }

  public drawTemplate(nDataRowsDOM: number) {
    this.rowsHeadCells.clear();
    for (let i = 0; i < nDataRowsDOM; i++) {
      for (let j = 0; j < this.nColumns; j++) {
        const rowHeadCell = document.createElement("div");
        rowHeadCell.setAttribute("data-cell-type", "rowHead");
        this.rowsHeadCells.set(`${i}-${j}`, rowHeadCell);
      }
    }
    this.pivotRowsHeadDOM.replaceChildren(...Array.from(this.rowsHeadCells.values()));
  }

  private internalGetCSSClass(
    j: number,
    iRowAbs: number,
    pivotIndex: IPivotChunkIndex,
    rowUnique: Array<string>,
    isSubTotal: boolean,
    keyContainMeasure: number,
    isMeasure: boolean | number,
    isParentFirst: boolean
  ) {
    let cssClass = "";

    if (j === 0) {
      cssClass += " first-of-row";
    }
    if (iRowAbs === pivotIndex?.allRowsLength - 1) {
      cssClass += " last-of-list";
    }
    if (isMeasure) {
      cssClass += " measure";
    } else {
      if (j >= rowUnique.length - +!isSubTotal && j < pivotIndex.maxOpenedFieldsOnRow - 1 - keyContainMeasure) {
        cssClass += " index-span";
      }
      if (isParentFirst) {
        cssClass += " index-new";
      }
    }

    return cssClass;
  }

  private internalPopulateTotalInfo(rowUnique: Array<string>, pivotIndex: IPivotChunkIndex, keyContainMeasure: number) {
    const isTotal = rowUnique?.length === 0;
    let isSubTotal = rowUnique?.length < pivotIndex.maxOpenedFieldsOnRow - keyContainMeasure;
    let fieldIndex = 0;
    let subTotalKey = "";
    while (isSubTotal && fieldIndex < rowUnique.length) {
      subTotalKey += subTotalKey ? VALUES_SEPARATOR + rowUnique[fieldIndex] : rowUnique[fieldIndex];
      isSubTotal = pivotIndex.openedRows.has(subTotalKey);
      fieldIndex++;
    }
    return {
      isTotal,
      isSubTotal,
    };
  }

  public fillTemplateWithData(
    opts: IPivotOptions,
    firstRowAbs: number,
    chunkManager: ChunkManager,
    pivotIndex: IPivotChunkIndex,
    nDataRowsDOM: number
  ) {
    const keyContainMeasure = +!opts.measuresOnColumn;
    let firstRowUnique =
      pivotIndex.rowsUniques?.[firstRowAbs - 1 - chunkManager.getChunkStartRow()]?.slice(
        0,
        -keyContainMeasure || undefined
      ) || [];

    for (let i = 0; i < nDataRowsDOM; i++) {
      const iRowAbs = i + firstRowAbs;
      const relativeRow = iRowAbs - chunkManager.getChunkStartRow();
      const rowUnique = pivotIndex.rowsUniques?.[relativeRow]?.slice(0, -keyContainMeasure || undefined);

      const totalInfo = this.internalPopulateTotalInfo(rowUnique, pivotIndex, keyContainMeasure);

      let isParentFirst = false;
      let isParentOpen = true;
      for (let j = 0; j < pivotIndex.maxOpenedFieldsOnRow; j++) {
        const rowCellDOM = this.rowsHeadCells.get(`${i}-${j}`);
        if (rowCellDOM) {
          let cssClass = "";
          let textContent = "";
          let path = "";

          if (rowUnique) {
            cssClass += "cell cell-header cell-header-row";

            const isMeasure = keyContainMeasure && j === pivotIndex.maxOpenedFieldsOnRow - keyContainMeasure;
            if (isMeasure) {
              textContent = getDescFromCode(
                pivotIndex.rowsUniques?.[relativeRow]?.slice(-1)?.[0],
                pivotIndex.codeToDesc
              );
            } else if (totalInfo.isSubTotal || totalInfo.isTotal) {
              if (firstRowUnique.length !== rowUnique.length && j >= rowUnique.length) {
                firstRowUnique = rowUnique;
                isParentFirst = true;
              }

              if (isParentFirst) {
                if (j === rowUnique.length) {
                  if (totalInfo.isTotal) {
                    textContent = "Totale";
                  } else {
                    textContent = `${getDescFromCode(
                      rowUnique.slice(-1)?.[0],
                      pivotIndex.codeToDesc,
                      opts.rows[rowUnique.length - 1].alias
                    )} [${"Totale"}]`;
                  }
                }
              }
            } else if (j < rowUnique.length) {
              if (isParentFirst || (firstRowUnique[j] !== rowUnique[j] && valueIsValid(rowUnique[j]))) {
                firstRowUnique.splice(j, pivotIndex.maxOpenedFieldsOnRow, rowUnique[j]);
                isParentFirst = true;
                if (j < opts.rows.length - 1) {
                  path = rowUnique.slice(0, j + 1).join(VALUES_SEPARATOR);
                  let isOpen = false;
                  if (isParentOpen) {
                    isOpen = pivotIndex.openedRows.has(path);
                    isParentOpen = isOpen;
                    cssClass += isOpen ? " open" : " close";
                  }
                }
                textContent = getDescFromCode(rowUnique?.[j], pivotIndex.codeToDesc, opts.rows[j].alias);
              }
            }

            cssClass += this.internalGetCSSClass(
              j,
              iRowAbs,
              pivotIndex,
              rowUnique,
              totalInfo.isSubTotal,
              keyContainMeasure,
              isMeasure,
              isParentFirst
            );
          }

          rowCellDOM.setAttribute("class", cssClass);
          rowCellDOM.setAttribute("data-head-path", path);
          rowCellDOM.textContent = textContent;
          const contentWidth = this.cellWidthCalculator.calculateCellWidth(textContent);
          if (contentWidth + CellWidthCalculator.TOLERANCE > COLUMN_WIDTH) {
            rowCellDOM.classList.add("need-tooltip");
          }
        }
      }
    }
  }
}
