import { IEntity, IPivotOptions } from "../domain/PivotOptionsDS";

export default class DimensionsDescrHead {
  private columnDimsDescrHeadDOM: HTMLElement;
  private rowDimsDescrHeadDOM: HTMLElement;

  constructor(columnDimsDescrHeadDOM: HTMLElement, rowDimsDescrHeadDOM: HTMLElement) {
    this.columnDimsDescrHeadDOM = columnDimsDescrHeadDOM;
    this.rowDimsDescrHeadDOM = rowDimsDescrHeadDOM;
  }

  private internalDrawColumnHead(columns: Array<IEntity>) {
    let dimsDescr = "";
    dimsDescr = (columns || [])
      .reduce((result: Array<string>, currentDim: IEntity) => {
        result.push(currentDim?.desc || "");
        return result;
      }, [])
      .join(" | ");
    this.columnDimsDescrHeadDOM.textContent = dimsDescr;
  }

  private internalDrawRowHead(opts: IPivotOptions, nColumnsOnRowHeader: number) {
    const dimsDescrCells = [];

    for (let j = 0; j < nColumnsOnRowHeader; j++) {
      const dimDescCell = document.createElement("div");
      dimDescCell.setAttribute("data-cell-type", "rowHead");
      dimDescCell.setAttribute("class", "cell cell-header");
      if (!opts.measuresOnColumn && j === nColumnsOnRowHeader - 1) {
        dimDescCell.textContent = "Misure";
      } else {
        dimDescCell.textContent = opts.rows?.[j]?.desc || "";
      }
      dimsDescrCells.push(dimDescCell);
    }
    this.rowDimsDescrHeadDOM.replaceChildren(...dimsDescrCells);
  }

  public draw(opts: IPivotOptions, nColumnsOnRowHeader: number) {
    this.internalDrawColumnHead(opts.columns);

    this.internalDrawRowHead(opts, nColumnsOnRowHeader);
  }

  public showColumnDimsHead(opts: IPivotOptions) {
    return (opts?.columns || []).length !== 0 || !opts.measuresOnColumn;
  }
}
